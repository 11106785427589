/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import {useAuth} from "../../hooks/AuthProvider";
import {Link} from "react-router-dom";
import ControlledComponent from "../AccessControl/ControlledComponent";
import Banner from "../Ui_Elements/Banner";
import SpinAnimation from "../Ui_Elements/SpinAnimation";
import {Table} from "antd";
import {itemRender, onShowSizeChange} from "../Pagination";
import PropTypes from "prop-types";
import ActionModal from "../Ui_Elements/ActionModal";
import {useNotifications} from "../../hooks/NotificationProvider";

import {deleteData, fetchList, putData} from "../../api/requestHandlers";
import {getCurrentISODateTime, getDateAndTime} from "../../utils";

export default function LicensesTable({caregiverID}) {
  const {token, user} = useAuth();
  const notifications = useNotifications();
  const [data, setData] = useState([]);
  const [modalVerificationText, setModalVerificationText] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [rowID, setRowID] = useState(null);
  const [rowItemVerified, setRowItemVerified] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: 'License name',
      dataIndex: `license_name`,
      render: (text, record) => (
        <>
          {
            record.license_file ?
              <Link
                to={record.license_file || "#"}
                target={`_blank`}
              >
                {text || "N/A"}
                <span>&nbsp;<i className="fa-solid fa-arrow-up-right-from-square"></i></span>
              </Link> : text
          }
        </>
      )
    },
    {
      title: 'License Number',
      dataIndex: 'license_number',
    },
    {
      title: "Issued By",
      dataIndex: "issued_by",
      render: (text, record) => (
        <>
          {
            record.issued_by ? text : "N/A"
          }
        </>
      )
    },
    {
      title: "Issue Date",
      dataIndex: "issued_date",
      render: (text, record) => (
        <>
          {
            record.issued_date ? getDateAndTime(text).date : "N/A"
          }
        </>
      )
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      render: (text, record) => (
        <>
          {
            record.expiry_date ? getDateAndTime(text).date : "N/A"
          }
        </>
      )
    },
    {
      title: "Verification Status",
      dataIndex: "license_verified_by",
      render: (text, record) => (
        <>
          {
            record.license_verified_by ? <span className="badge bg-success-light">Verified</span>
              : <span className="badge bg-danger-light">Pending</span>
          }
        </>
      )
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (text, record) => (
        <ControlledComponent allowedRoles={["admin"]} allowedUsers={[caregiverID]}>
          <div className="text-end">
            <div
              className="dropdown dropdown-action"
              onClick={() => {
                setRowID(record.id);
                setRowItemVerified(!!record.license_verified_by);
              }}
            >
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v"/>
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item"
                      to={`/add-qualification?type=license&caregiverID=${caregiverID}&id=${record.id}`}>
                  <i className="far fa-edit me-2"/>
                  Edit
                </Link>
                <Link className="dropdown-item"
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete-license-modal">
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
                <ControlledComponent allowedRoles={["admin"]}>
                  <Link className="dropdown-item"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#verify-or-revoke-license">
                    <div>
                      <i className="fa-regular fa-circle-dot m-r-5"></i>&nbsp;
                      { record.license_verified_by ? "Revoke" : "Verify" }
                    </div>
                  </Link>
                </ControlledComponent>
              </div>
            </div>
          </div>
        </ControlledComponent>
      ),
    }
  ];

  async function revokeOrApproveDocument() {
    // Revoke the document if it is approved, otherwise approve it if it is unverified.
    // Verification is done by setting the fields to an empty string
    if (!rowID) {
      notifications.addNotification("Row id is null", "error");
      return;
    }
    const d = {
      license_verified_by: rowItemVerified ? "" : user.id,
      license_verified_at: rowItemVerified ? "" : getCurrentISODateTime(),
    };
    try {
      setLoading(true);
      await putData(`caregiver-licensing/${rowID}`, token, d);
      notifications.addNotification("Item successfully updated", "success");
      setRefreshKey(refreshKey => refreshKey + 1);
    } catch (e) {
      console.error(`Error in updating license #${rowID}: ${e}`);
      notifications.addNotification(e.message, "error");
    } finally {
      setLoading(false);
    }
  }

  async function deleteItem() {
    try {
      setLoading(true);
      if (!rowID) {
        notifications.addNotification("Row id is null", "error");
      } else {
        await deleteData(`caregiver-licensing/${rowID}`, token);
        notifications.addNotification("Item successfully deleted", "success");
        setRefreshKey(refreshKey => refreshKey + 1);
      }
    } catch (err) {
      notifications.addNotification(err.message, "error");
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    setModalVerificationText(`Are you sure you want to set this item as ${rowItemVerified ? "'unverified'" : "'verified'"}?`);
  }, [rowItemVerified]);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        if (caregiverID) {
          const apiData = await fetchList(`caregiver-licensing?paginate=0&health_care_personnel_id=${caregiverID}`, token, false);
          setData(apiData);
        } else {
          console.error("The document type has not been specified");
          setError("Error on loading licenses. Please refresh the page or contact support if problem persists.");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [caregiverID, refreshKey]);

  return (
    <>
      {
        error ? <Banner text={error} type="error" dismissible={false}/> :
          <div className="row">
            <div className="col-sm-12">
              <ControlledComponent allowedRoles={["admin"]} allowedUsers={[caregiverID]}>
                <div className="float-end">
                  <Link
                    to={`/add-qualification?type=license&caregiverID=${caregiverID}`}
                    className="btn btn-primary">
                    Add a license
                  </Link>
                </div>
              </ControlledComponent>
              <div className="card card-table">
                <div className="card-body p-4">
                  {loading ? <SpinAnimation/> : <div className="table-responsive">
                    <Table
                      pagination={{
                        total: data.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={data.data}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                    />
                  </div>
                  }
                </div>
              </div>
            </div>
            <ActionModal
              actionOnAccept={deleteItem}
              text="Are you sure you want to delete this item?"
              id="delete-license-modal"
            />
            <ControlledComponent allowedRoles={["admin"]}>
              <ActionModal
                actionOnAccept={revokeOrApproveDocument}
                text={modalVerificationText}
                id="verify-or-revoke-license"
              />
            </ControlledComponent>
          </div>
      }
    </>
  )
}

LicensesTable.propTypes = {
  caregiverID: PropTypes.string.isRequired,
}
