/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Table} from "antd";
import {Link} from "react-router-dom";
import {itemRender, onShowSizeChange} from "../../Pagination";
import {useAuth} from "../../../hooks/AuthProvider";

import SpinAnimation from "../../Ui_Elements/SpinAnimation";
import Banner from "../../Ui_Elements/Banner";
import Delete_Invoices from "../Add_Invoices/Delete_Invoices";
import InvoiceModal from "../InvoiceModal";
import dayjs from "dayjs";
import FilterBar from "../../Ui_Elements/FilterBar";
import {fetchList} from "../../../api/requestHandlers";

const InvoiceTable = ({invoiceType, appointmentID, invoiceStatus, caregiverID, patientID, dateFrom, dateTo}) => {

  const {token, userRoles, user} = useAuth();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");

  // For tracking which row has been clicked
  const [rowID, setRowID] = useState("");
  const [rowAppointmentID, setRowAppointmentID] = useState("");

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      sorter: (a, b) => a.invoice_number - b.invoice_number,
      render: (text, record) => (
        <>
          <Link to={`/invoice-details?type=${invoiceType}&id=${record.id}`}>{record.invoice_number}</Link>
          {/*<Link*/}
          {/*      to="#"*/}
          {/*      onClick={() => {*/}
          {/*        setRowID(record.id);*/}
          {/*        setRowAppointmentID(record.appointment_schedule_id);*/}
          {/*      }}*/}
          {/*      data-bs-toggle="modal"*/}
          {/*      data-bs-target="#view_invoice_details">*/}
          {/*  {record.invoice_number}*/}
          {/*</Link>*/}
        </>
      ),
    },
    {
      title: "Appointment ID",
      dataIndex: "appointment_schedule_id",
      render: (text, record) => (
        <>
          <Link
            to={`/appointment-details?id=${record.appointment_schedule_id}&patientID=${record.patient_id || ""}&caregiverID=${record.health_care_personnel_id || ""}`}>{record.appointment_schedule_id}</Link>
        </>
      )
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      render: (text, record) => (
        <>
          <div className="text-primary">Ksh.{record.total_amount?.toLocaleString()}</div>
        </>
      )
    },
    {
      title: "Issue Date",
      dataIndex: "invoice_date",
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      sorter: (a, b) => a.due_date.localeCompare(b.due_date),
    },
    {
      title: invoiceType === 'patient' ? 'Patient Name' : 'Staff Name',
      dataIndex: invoiceType === "patient" ? "patient_name" : "health_care_personnel_name",
      sorter: (a, b) => {
        if (invoiceType === 'patient') {
          a.patient_name.localeCompare(b.patient_name)
        } else {
          a.health_care_personnel_name.localeCompare(b.health_care_personnel_name)
        }
      },
      render: (text, record) => (
        <Link to={"profile"}>{text}</Link>
      )
    },
    {
      title: "Addressed to",
      dataIndex: "addressed_to",
      sorter: (a, b) => a.addressed_to.localeCompare(b.addressed_to),
      render: (text, record) => (
        <Link to={"profile"}>{text}</Link>
      )
    },
    {
      title: "Status",
      dataIndex: "invoice_status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text, record) => (
        <div>
          {text === "Paid" && (
            <span className="badge bg-success-light">
                            {text}
                        </span>
          )}
          {text === "Overdue" && (
            <span className="badge bg-danger-light">
                            {text}
                        </span>
          )}
          {text === "Cancelled" && (
            <span className="badge bg-primary-light">
                            {text}
                        </span>
          )}
          {text === "Pending" && (
            <span className="badge bg-primary-light">
                            {text}
                        </span>
          )}
        </div>
      )
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (text, record) => (
        userRoles.includes("admin") ? <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v"/>
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item"
                      to={`/add-invoice?invoiceID=${record.id}&invoiceType=${invoiceType}&patientID=${record.patient_id || ""}&caregiverID=${record.health_care_personnel_id || ""}&appointmentID=${record.appointment_schedule_id || ""}`}>
                  <i className="far fa-edit me-2"/>
                  Edit
                </Link>
                <Link className="dropdown-item"
                      to="#"
                      onClick={() => {
                        setRowID(record.id)
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#delete_invoices_details">
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </> : ""
      ),
    },
  ]


  useEffect(() => {
    setError("");
    if (!invoiceType) {
      setError("Invoice type had not been defined in the query string");
      return;
    }
    const loadData = async () => {
      setLoading(true);
      try {
        let q = "";
        if (appointmentID) {
          q += `appointment_schedule_id=${appointmentID}&`;
        }
        if (invoiceStatus) {
          q += `invoice_status=${invoiceStatus}&`;
        }
        // Set date filters, defaults to dates ranging from the earliest to the max date possible.
        q += `invoice_date_start=${dateFrom || "0001-01-01"}&`;
        q += `invoice_date_end=${dateTo || "9999-12-12"}&`;
        if (userRoles.includes("admin")) {
          // Get invoices for the specified patient/staff, or fetch all invoices
          if (patientID) {
            q += `patient_id=${patientID}&`
          }
          if (caregiverID) {
            q += `health_care_personnel_id=${caregiverID}&`
          }
        } else if (userRoles.includes("patient")) {
          q += `patient_id=${user.id}&`;
        } else if (userRoles.includes("caregiver")) {
          q += `health_care_personnel_id=${user.id}&`;
        }
        const apiData = await fetchList(`${invoiceType}-invoice?paginate=0&${q}`, token, false); // Fetch the data
        setData(apiData); // Update the state with the fetched data
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [invoiceType, invoiceStatus, dateFrom, dateTo]);

  return (
    error ? <Banner text={error} type="error" dismissible={false}/> :
      <div className="row">
        <div className="col-sm-12">
          <div className="card card-table">
            <div className="card-body p-4">
              {loading ? <SpinAnimation/> : <div className="table-responsive">

                <Table
                  pagination={{
                    total: data.length,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    // showSizeChanger: true,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                  }}
                  columns={columns}
                  dataSource={data.data}
                  rowSelection={rowSelection}
                  rowKey={(record) => record.id}
                />
              </div>}
            </div>
          </div>
        </div>
        <Delete_Invoices invoiceType={invoiceType} invoiceID={rowID}/>
        <InvoiceModal appointmentID={rowAppointmentID} invoiceType={invoiceType} invoiceID={rowID} />
      </div>
  );
};

InvoiceTable.propTypes = {
  invoiceType: PropTypes.string.isRequired,
  appointmentID: PropTypes.string,
  invoiceStatus: PropTypes.string,
  caregiverID: PropTypes.string,
  patientID: PropTypes.string,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
}

export default InvoiceTable;
