/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import Header from '../Header';
import Sidebar from '../Sidebar';
import {Link} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import useErrorNotification from "../../hooks/useErrorNotification";
import ContentWrapper from "../Dashboard/ContentWrapper";
import {useAuth} from "../../hooks/AuthProvider";
import LeadsTable from "./LeadsTable";
import useCustomNavigation from "../../hooks/useCustomNavigation";
import LeadDetails from "./LeadDetails";

const LeadsPage = () => {

  const { userRoles } = useAuth();
  const [id, setId] = React.useState(null);
  const { queryParams } = useCustomNavigation();
  const [error, setError] = useState("");
  useErrorNotification(error);

  useEffect(() => {
    setId(queryParams.get("id"));
  }, [queryParams]);

  return (
    <>
      <Header/>
      <Sidebar/>
      <>
        <div className="page-wrapper">
          <ContentWrapper>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Service Requests</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right"/>
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      { id ? "Details" : "Table" }
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}

            {/* Leads Table */}
            {
              id ? <LeadDetails id={id} />: <LeadsTable />
            }
          <div className="mb-3">&nbsp;</div>
          </ContentWrapper>
        </div>
      </>
    </>
  );
}
export default LeadsPage;