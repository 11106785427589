/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import Header from '../../Header'
import Sidebar from '../../Sidebar'
import {Link} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import ContentWrapper from "../../Dashboard/ContentWrapper";
import useCustomNavigation from "../../../hooks/useCustomNavigation";
import Banner from "../../Ui_Elements/Banner";
import {useAuth} from "../../../hooks/AuthProvider";
import SpinAnimation from "../../Ui_Elements/SpinAnimation";
import ControlledComponent from "../../AccessControl/ControlledComponent";
import NextOfKinTable from "../../patients/NextOfKinTable";
import InvoiceTableWithFilter from "../../Invoice/Invoice-List/InvoiceTableWithFilter";
import AppointmentsCalendar from "../../appointments/AppointmentsCalendar";
import AvatarImage from "../../Ui_Elements/AvatarImage";
import {fetchData} from "../../../api/requestHandlers";
import LicensesTable from "../../ProfessionalQualifications/LicenseTable";
import CertificationTable from "../../ProfessionalQualifications/CertificationTable";

const Profile = () => {
  const {token} = useAuth();
  const {queryParams, activeTabClassName, setActiveTabURL} = useCustomNavigation();
  const id = queryParams.get("id");
  const [error, setError] = useState("");
  const [fatalError, setFatalError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [userDataRolesString, setUserDataRolesString] = useState([]);
  const defaultTab = "about-cont"

  // Check if role and id have been specified in the query string and that
  // the role is valid.
  useEffect(() => {
    if (!id) {
      setFatalError(true);
      setError("The User ID has not been specified in the URL");
    }
  }, []);

  // Load the user
  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const apiData = await fetchData(`user/${id}`, token);

        // TODO: Verify that the user has sufficient permissions to view the profile

        // Concatenate all the roles as a single string
        if (apiData) {
          let roles = "";
          apiData.roles.map((role) => {
            roles += roles ? ` | ${role.name}` : role.name;
          });
          if (!roles) {
            roles = "No role assigned";
          }
          setUserDataRolesString(roles);
        }

        setUserData(apiData);
      } catch (err) {
        console.error(err.message);
        setFatalError(true);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    loadData().then(
      () => {
        // console.log("Basic user data loaded")
      }
    );
  }, []);

  return (
    <>
      <Header/>
      <Sidebar/>
      <div className="page-wrapper">
        <ContentWrapper>
          {
            fatalError ? <Banner text={error} type={"error"} dismissible={false}/> :
              <>
                {
                  loading ? <SpinAnimation/> :
                    <>
                      <div className="row">
                        <div className="col-sm-7 col-6">
                          <ul className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard </Link></li>
                            <li className="breadcrumb-item">
                              <i className="feather-chevron-right">
                                <FeatherIcon icon="chevron-right"/>
                              </i>
                            </li>
                            <li className="breadcrumb-item active">Profile</li>
                          </ul>
                        </div>
                        {
                          <ControlledComponent allowedRoles={["admin"]} allowedUsers={[userData.id]}>
                            <div className="col-sm-5 col-6 text-end m-b-30">
                              <Link to={`/edit-profile?id=${userData.id}`} className="btn btn-primary btn-rounded"><i
                                className="fa fa-plus"/> Edit
                                Profile</Link>
                            </div>
                          </ControlledComponent>
                        }
                      </div>

                      {/* Profile Section */}

                      {/* Header */}
                      <div className="card-box profile-header">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="profile-view">
                              <div className="profile-img-wrap">
                                <div className="profile-img">
                                  <AvatarImage imgURL={userData.user_images?.thumbnail || ""}/>
                                </div>
                              </div>
                              <div className="profile-basic">
                                <div className="row">
                                  <div className="col-md-5">
                                    <div className="profile-info-left">
                                      <h3 className="user-name m-t-0 mb-0">{userData.name}</h3>
                                      <small className="text-muted">{userDataRolesString}</small>
                                      {/*<div className="staff-id">ID : {userData.id}</div>*/}
                                      {
                                        userDataRolesString.includes("caregiver") ?
                                          <ControlledComponent allowedRoles={["admin", "patient"]}>
                                            <div className="staff-msg">
                                              <Link
                                                to={`/addappointments?caregiverID=${userData.id}`}
                                                className="btn btn-primary">
                                                Make An Appointment
                                              </Link>
                                            </div>
                                          </ControlledComponent> : null
                                      }
                                    </div>
                                  </div>
                                  <div className="col-md-7">
                                    <ul className="personal-info">
                                      <ControlledComponent allowedRoles={["admin"]} allowedUsers={[userData.id]}>
                                        <li>
                                          <span className="title">Phone:</span>
                                          <span className="text"><Link
                                            to={`tel:${userData.phone_number || ""}`}>{userData.phone_number || "N/A"}</Link></span>
                                        </li>
                                      </ControlledComponent>
                                      <ControlledComponent allowedRoles={["admin"]} allowedUsers={[userData.id]}>
                                        <li>
                                          <span className="title">Email:</span>
                                          <span className="text"><Link
                                            to={`mailto:${userData.email || ""}`}>{userData.email || "N/A"}</Link></span>
                                        </li>
                                      </ControlledComponent>
                                      <ControlledComponent allowedRoles={["admin"]} allowedUsers={[userData.id]}>
                                        <li>
                                          <span className="title">Age:</span>
                                          <span className="text">{userData.age || "N/A"}</span>
                                        </li>
                                      </ControlledComponent>
                                      <ControlledComponent allowedRoles={["admin"]} allowedUsers={[userData.id]}>
                                        <li>
                                          <span className="title">National ID:</span>
                                          <span className="text">{userData.id_number || "N/A"}</span>
                                        </li>
                                      </ControlledComponent>
                                      <ControlledComponent allowedRoles={["admin"]} allowedUsers={[userData.id]}>
                                        <li>
                                          <span className="title">Location:</span>
                                          <span className="text">{userData.location || "N/A"}</span>
                                        </li>
                                      </ControlledComponent>
                                      <li>
                                        <span className="title">Gender:</span>
                                        <span className="text">{userData.gender || "N/A"}</span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Profile Section */}
                      <div className="profile-tabs">
                        <ul className="nav nav-tabs nav-tabs-bottom">
                          <li className="nav-item">
                            <Link
                              className={`nav-link ${activeTabClassName("about-cont", defaultTab)}`}
                              to="#about-cont"
                              data-bs-toggle="tab"
                              onClick={() => {setActiveTabURL("about-cont")}}
                            >
                              About
                            </Link></li>
                          {
                            userDataRolesString.includes("caregiver") ?
                              <li className="nav-item">
                                <Link
                                  className={`nav-link ${activeTabClassName("qualifications", defaultTab)}`}
                                  to="#qualifications"
                                  data-bs-toggle="tab"
                                  onClick={() => {setActiveTabURL("qualifications")}}
                                >
                                  Professional Qualifications
                                </Link>
                              </li> : null
                          }
                          {/* Only admins or the profile owner can see this*/}
                          <ControlledComponent allowedRoles={["admin"]}>
                            {
                              userDataRolesString.includes("caregiver") || userDataRolesString.includes("patient") ?
                                <li className="nav-item">
                                  <Link
                                    className={`nav-link ${activeTabClassName("appointments", defaultTab)}`}
                                    to="#appointments"
                                    data-bs-toggle="tab"
                                    onClick={() => {setActiveTabURL("appointments")}}
                                  >
                                    Appointments
                                  </Link>
                                </li> : null
                            }
                          </ControlledComponent>
                          {/* Only admins or the profile owner can see this*/}
                          <ControlledComponent allowedRoles={["admin"]} allowedUsers={[userData.id]}>
                            {
                              userDataRolesString.includes("caregiver") || userDataRolesString.includes("patient") ?
                                <li className="nav-item">
                                  <Link
                                    className={`nav-link ${activeTabClassName("invoices", defaultTab)}`}
                                    to="#invoices"
                                    data-bs-toggle="tab"
                                    onClick={() => {setActiveTabURL("invoices")}}
                                  >
                                    Invoices
                                  </Link>
                                </li> : null
                            }
                          </ControlledComponent>
                          <ControlledComponent allowedRoles={["admin"]} allowedUsers={[userData.id]}>
                            {
                              userDataRolesString.includes("patient") ?
                                <li className="nav-item">
                                  <Link
                                    className={`nav-link ${activeTabClassName("next-of-kin", defaultTab)}`}
                                    onClick={() => {setActiveTabURL("next-of-kin")}}
                                    to="#next-of-kin"
                                    data-bs-toggle="tab"
                                  >Next of Kins
                                  </Link>
                                </li> : null
                            }
                          </ControlledComponent>
                        </ul>
                        <div className="tab-content">
                          {/* Bio Tab */}
                          <div className={`tab-pane ${activeTabClassName("about-cont", defaultTab, true)}`} id="about-cont">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="col-md-8">
                                  <div className="card-box">
                                    <h3 className="card-title">Bio</h3>
                                    <p>{userData.bio || "Hello👋 I'm yet to update my bio. Feel free to get in touch"}</p>
                                  </div>
                                </div>
                                <ControlledComponent allowedRoles={["admin"]} allowedUsers={[userData.id]}>
                                  <div className="card-box">
                                    <h3 className="card-title">Location Information</h3>
                                    <p>{userData.location}</p>
                                  </div>
                                </ControlledComponent>
                              </div>
                            </div>
                          </div>
                          {/* Qualifications Tab (licenses and certifications) */}
                          {
                            userDataRolesString.includes("caregiver") ?
                              <div className={`tab-pane ${activeTabClassName("qualifications", defaultTab, true)}`} id="qualifications">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="card-box">
                                      <h3 className="card-title">Licenses</h3>
                                      <LicensesTable caregiverID={userData.id} />
                                    </div>
                                    <div className="card-box">
                                      <h3 className="card-title">Certifications</h3>
                                      <CertificationTable caregiverID={userData.id} type={"certification"}/>
                                    </div>
                                  </div>
                                </div>
                              </div>: null
                          }
                          {/* Appointments Tab*/}
                          <ControlledComponent allowedRoles={["admin"]}>
                            <div className={`tab-pane ${activeTabClassName("appointments", defaultTab, true)}`} id="appointments">
                                  <div className="row">
                                    {(() => {
                                      if (userDataRolesString.includes("caregiver")) {
                                        return <AppointmentsCalendar userID={userData.id} role="caregiver"/>;
                                      } else if (userDataRolesString.includes("patient")) {
                                        return <AppointmentsCalendar userID={userData.id} role="patient"/>;
                                      }
                                      return null;
                                    })()}
                                  </div>
                            </div>
                          </ControlledComponent>
                          {/* Invoices Tab*/}
                          <ControlledComponent allowedRoles={["admin"]} allowedUsers={[userData.id]}>
                            <div className={`tab-pane ${activeTabClassName("invoices", defaultTab, true)}`} id="invoices">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="card">
                                    {(() => {
                                      if (userDataRolesString.includes("caregiver")) {
                                        return <InvoiceTableWithFilter invoiceType="caregiver" caregiverID={userData.id} />;
                                      } else if (userDataRolesString.includes("patient")) {
                                        return <InvoiceTableWithFilter invoiceType="patient" patientID={userData.id} />;
                                      }
                                      return null;
                                    })()}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ControlledComponent>

                          {/* Next of Kin Tab*/}
                          <ControlledComponent allowedRoles={["admin"]} allowedUsers={[userData.id]}>
                            <div className={`tab-pane ${activeTabClassName("next-of-kin", defaultTab, true)}`} id="next-of-kin">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="card-box">
                                    <h3 className="card-title">List of Next of Kins</h3>
                                    <NextOfKinTable patientID={userData.id}/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ControlledComponent>
                        </div>
                      </div>
                    </>
                }
              </>
          }
        </ContentWrapper>
      </div>
    </>
  )
}

export default Profile
