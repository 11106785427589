/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar";
import ContentWrapper from "../Dashboard/ContentWrapper";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import AddAppointmentForm from "./AddAppointmentForm";
import InvoiceTable from "../Invoice/Invoice-List/InvoiceTable";
import useCustomNavigation from "../../hooks/useCustomNavigation";
import {useAuth} from "../../hooks/AuthProvider";
import ControlledComponent from "../AccessControl/ControlledComponent";
import RatingForm from "../ServiceRating/ServiceRating";
import {useNotifications} from "../../hooks/NotificationProvider";
import {fetchData, fetchList} from "../../api/requestHandlers";
import Banner from "../Ui_Elements/Banner";
import SpinAnimation from "../Ui_Elements/SpinAnimation";

export default function AppointmentDetails() {
  const {userRoles, token} = useAuth();
  const notifications = useNotifications();
  const [error, setError] = useState("");
  const {queryParams, activeTabClassName, setActiveTabURL} = useCustomNavigation();
  const defaultTab = "appointment-form";
  const patientID = queryParams.get("patientID");
  const caregiverID = queryParams.get("caregiverID");
  const appointmentID = queryParams.get("id");
  const [appointmentData, setAppointmentData] = useState(null);
  const [ratingData, setRatingData] = useState(null);

  // Load appointment details
  useEffect(() => {
    async function loadAppointmentData() {
      try {
        const details = await fetchData(`appointment-schedule/${appointmentID}`, token);
        setAppointmentData(details);
      } catch (e) {
        console.error(e);
        setError(`Error loading appointment data: ${e.message}`);
      }
    }
    if (appointmentID) {
      loadAppointmentData();
    }
  }, [appointmentID])

  // Load service rating data
  useEffect(() => {
    async function loadRatingData() {
      try {
        const ratings = await fetchList(`caregiver-rating?appointment_schedule_id=${appointmentID}&paginate=0`, token, false);
        if (ratings?.data?.length > 0) {
          // Assumes there is only one rating per appointment
          setRatingData(ratings.data[0]);
        }
      } catch (e) {
        console.error(e);
        notifications.addNotification(`Error loading rating data: ${e.message}`);
      }
    }
    if (appointmentID) {
      loadRatingData();
    }
  }, [appointmentID])

  return (
    <div>
      <Header/>
      <Sidebar
        id="menu-item4"
        // id1="menu-items4"
      />
      <>
        <div className="page-wrapper">
          <ContentWrapper>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Appointment </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right"/>
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Appointment Details
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {
              error ? <Banner type="error" text={error} dismissible={false} /> :
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-body">
                        <ul className="nav nav-tabs nav-fill nav-justified nav-tabs-solid nav-tabs-rounded">
                          <li className="nav-item">
                            <Link
                              className={`nav-link ${activeTabClassName("appointment-form", defaultTab)}`}
                              onClick={() => {setActiveTabURL("appointment-form")}}
                              to="#appointment-form"
                              data-bs-toggle="tab">Appointment Details
                            </Link>
                          </li>
                          {
                            userRoles.includes("patient") || userRoles.includes("admin") ?
                              <li className="nav-item">
                                <Link
                                  to="#patient-invoices"
                                  className={`nav-link ${activeTabClassName("patient-invoices", defaultTab)}`}
                                  onClick={() => {setActiveTabURL("patient-invoices")}}
                                  data-bs-toggle="tab">
                                  {
                                    userRoles.includes("patient") ? "Invoices" : "Patient Invoices"
                                  }
                                </Link>
                              </li> : ""
                          }
                          {
                            userRoles.includes("caregiver") || userRoles.includes("admin") ?
                              <li className="nav-item">
                                <Link
                                  to="#staff-invoices"
                                  className={`nav-link ${activeTabClassName("staff-invoices", defaultTab)}`}
                                  onClick={() => {setActiveTabURL("staff-invoices")}}
                                  data-bs-toggle="tab">
                                  {
                                    userRoles.includes("caregiver") ? "Invoices" : "Healthcare Professional Invoices"
                                  }
                                </Link>
                              </li> : ""
                          }
                          <ControlledComponent allowedRoles={["admin"]} allowedUsers={[appointmentData?.patient_id]}>
                            <li className="nav-item">
                              <Link
                                to="#service-rating"
                                className={`nav-link ${activeTabClassName("service-rating", defaultTab)}`}
                                onClick={() => {setActiveTabURL("service-rating")}}
                                data-bs-toggle="tab">
                                Rating
                              </Link>
                            </li>
                          </ControlledComponent>
                        </ul>
                        {
                          appointmentData ?
                            <div className="tab-content">
                              <div className={`tab-pane ${activeTabClassName("appointment-form", defaultTab, true)}`} id="appointment-form">
                                <AddAppointmentForm appointmentID={appointmentID} appointmentData={appointmentData} />
                              </div>
                              {
                                userRoles.includes("patient") || userRoles.includes("admin") ?
                                  <div className={`tab-pane ${activeTabClassName("patient-invoices", defaultTab, true)}`} id="patient-invoices">
                                    {
                                      userRoles.includes("admin") ?
                                        <Link
                                          to={`/add-invoice?appointmentID=${appointmentID}&invoiceType=patient&patientID=${patientID}`}
                                          className="btn btn-primary">
                                          Add Invoice
                                        </Link> : ""
                                    }
                                    <InvoiceTable
                                      appointmentID={appointmentID}
                                      invoiceType="patient"
                                      patientID={appointmentData?.patient_id}
                                    />
                                  </div> : ""
                              }
                              {
                                userRoles.includes("caregiver") || userRoles.includes("admin") ?
                                  <div className={`tab-pane ${activeTabClassName("staff-invoices", defaultTab, true)}`} id="staff-invoices">
                                    {
                                      userRoles.includes("admin") ?
                                        <Link
                                          to={`/add-invoice?appointmentID=${appointmentID}&invoiceType=caregiver&caregiverID=${caregiverID}`}
                                          className="btn btn-primary">
                                          Add Invoice
                                        </Link> : ""
                                    }

                                    <InvoiceTable
                                      appointmentID={appointmentID}
                                      invoiceType="caregiver"
                                      caregiverID={appointmentData.health_care_personnel_id}
                                    />
                                  </div> : ""
                              }
                              <ControlledComponent allowedRoles={["admin"]} allowedUsers={[appointmentData?.patient_id]}>
                                <div className={`tab-pane ${activeTabClassName("service-rating", defaultTab, true)}`} id="service-rating">
                                  <RatingForm
                                    appointmentID={appointmentData.id}
                                    caregiverID={appointmentData.health_care_personnel_id}
                                    ratingData={ratingData}
                                  />
                                </div>
                              </ControlledComponent>
                            </div> : <SpinAnimation />
                        }
                      </div>
                    </div>
                  </div>
                </div>
            }
          </ContentWrapper>
        </div>
      </>
    </div>
  );
}