/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import {useAuth} from "../../hooks/AuthProvider";
import {fetchData, putData} from "../../api/requestHandlers";
import SpinAnimation from "../Ui_Elements/SpinAnimation";
import Banner from "../Ui_Elements/Banner";
import {getDateAndTime} from "../../utils";
import Select from "react-select";
import {leadStatusOptions, prettifyCaseStatus} from "./data";
import {useNotifications} from "../../hooks/NotificationProvider";
import useCustomNavigation from "../../hooks/useCustomNavigation";


export default function LeadDetails({id}) {
  const {token} = useAuth();
  const {reload} = useCustomNavigation();
  const notifications = useNotifications();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const [selectedStatus, setSelectedStatus] = useState(null);
  const statusOptions = leadStatusOptions.map(status => ({
    value: status,
    label: prettifyCaseStatus(status),
  }));

  // Map the keys in the data to more a human-readable format
  const keyMap = new Map([
    ['case_number', 'Request ID'],
    ['created_at', 'Created On'],
    ['source_of_lead', 'Origin'],
    ['case_description', 'Description'],
    ['case_status', 'Status'],
    ['patient_name', '👤 Name of Client'],
    ['phone_number', '☎️ Phone number'],
    ['location', "📍 Client's Location"],
    ['service_name', 'Service Requested'],
    ['requested_start_date', 'Requested Appointment Date'],
  ]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const resp = await fetchData(`care-request-cases/${id}`, token);
        setData(resp);
        setSelectedStatus({value: resp.case_status, label: prettifyCaseStatus(resp.case_status)});
      } catch (e) {
        setError(e.message)
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [id])

  async function changeStatus (event) {
    event.preventDefault();
    try {
      setLoading(true);
      await putData(`care-request-cases/${data.id}`, token, {case_status: selectedStatus.value});
      notifications.addNotification('Status successfully updated', 'success');
      reload();
    } catch (e) {
      notifications.addNotification(e.message, 'error');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {/* Lead Details */}
      {
        error ? <Banner text={error} type="error" dismissible={false}/> :
          <>
            <div className="card">
              <div className="staff-search-table">
                <form>
                  <div className="row">
                    <div className="col-6 col-md-6 col-xl-3">
                      <div className="form-group local-forms">
                        <label>
                          Status
                        </label>
                        <Select
                          menuPortalTarget={document.body}
                          value={selectedStatus}
                          onChange={setSelectedStatus}
                          options={statusOptions}
                          components={{
                            IndicatorSeparator: () => null
                          }}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                              boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                              '&:hover': {
                                borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                              },
                              borderRadius: '10px',
                              fontSize: "14px",
                              minHeight: "45px",
                            }),
                            dropdownIndicator: (base, state) => ({
                              ...base,
                              transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                              transition: '250ms',
                              width: '35px',
                              height: '35px',
                            }),
                          }}
                        />
                      </div>
                    </div>

                    {/* Button for changing the case status */}
                    <div className="col-6 col-md-6 col-xl-3">
                      <div className="form-group local-forms doctor-submit">
                        <button
                          className="btn btn-primary submit-list-form me-2"
                          disabled={data?.case_status === selectedStatus?.value}
                          onClick={changeStatus}
                        >
                          Change Status
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                {
                  loading ? <SpinAnimation/> :
                    <table className="table table-responsive">
                      <tbody>
                      {[...keyMap.keys()].map((key, index) => (
                        <tr key={key} className={index % 2 === 0 ? "bg-white" : "bg-light"}>
                          <td className="fw-bold">{keyMap.get(key)}</td>
                          <td>
                            {
                              data[key] ? (
                                ['created_at', 'requested_start_date'].includes(key) ?
                                  getDateAndTime(data[key], false, true).datetime : data[key]
                              ) : "N/A"
                            }
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                }
              </div>
            </div>
          </>
      }
    </>
  );

}

LeadDetails.propTypes = {
  id: PropTypes.string.isRequired,
}