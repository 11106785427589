// Defines constants to be used by lead-related components

const _leadStatusOptions = [
  'inquiry', 'assessment_scheduled', 'assessment_completed',
  'care_plan_development', 'quote_provided', 'caregiver_matching',
  'scheduling_in_process' ,'care_started',
  'on_hold', 'declined', 'referred_out',
];

/**
 * Convert a case status to a human-readable string
 * @param {string} status - value of case status as returned by the API
 * @returns {string}
 */
export function prettifyCaseStatus(status) {
  return status.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
}

// Sort the array
export const leadStatusOptions = _leadStatusOptions.toSorted((a, b) => a.localeCompare(b));

// Class names for color-coding the statuses
export const statusClasses = {
  inquiry: "badge bg-primary",
  assessment_completed: "badge bg-success-light",
  scheduling_in_process: "badge bg-primary-light",
  assessment_scheduled: "badge bg-primary-light",
  quote_provided: "badge bg-primary-light",
  care_plan_development: "badge bg-primary-light",
  caregiver_matching: "badge bg-primary-light",
  care_started: "badge bg-primary-light",
  declined: "badge bg-danger-light",
  on_hold: "badge bg-secondary",
  referred_out: "badge bg-secondary",
};
// Default class if the text is not found in the mapping
export const defaultClass = "badge bg-secondary-light";