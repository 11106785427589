import React from 'react'
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

/**
 * A generic component that displays a modal for prompting the user
 * if they are sure they wish to perform a certain action. Both
 * async and non-async functions are accepted for all functional parameters.
 *
 * @param text - the text to be used in the modal's body
 * @param actionOnAccept - the function to execute if the user says yes to the prompt.
 * @param actionOnReject - the function to execute if the user says no to the prompt
 * @param id - the id to use for the modal.
 * @returns {React.JSX.Element}
 */
const ActionModal = ({text, actionOnAccept, actionOnReject, id = "action-modal"} ) => {
  async function performActionOnAccept() {
    if (actionOnAccept) {
      await actionOnAccept();
    }
  }

  async function performActionOnReject() {
    if (actionOnReject) {
      await actionOnReject();
    }
  }

  return (
    <div>
      <div className="modal custom-modal modal-bg fade" id={id} role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h4>{text}</h4>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  <div className="col-6">
                    <Link
                      to={"#"}
                      data-bs-dismiss="modal"
                      className="btn btn-primary paid-continue-btn"
                      onClick={performActionOnAccept}
                    >
                      Confirm
                    </Link>
                  </div>
                  <div className="col-6">
                    <Link
                      to={"#"}
                      data-bs-dismiss="modal"
                      className="btn btn-primary paid-cancel-btn"
                      onClick={performActionOnReject}
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ActionModal.propTypes = {
  text: PropTypes.string.isRequired,
  actionOnAccept: PropTypes.func.isRequired,
  actionOnReject: PropTypes.func,
  id: PropTypes.string,
};

export default ActionModal;
