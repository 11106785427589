/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {Table} from "antd";
import SpinAnimation from "../Ui_Elements/SpinAnimation";
import {itemRender, onShowSizeChange} from '../Pagination'
import {Link} from 'react-router-dom';
import {useAuth} from "../../hooks/AuthProvider";
import useErrorNotification from "../../hooks/useErrorNotification";
import Banner from "../Ui_Elements/Banner";
import ControlledComponent from "../AccessControl/ControlledComponent";
import {fetchList} from "../../api/requestHandlers";
import {getDateAndTime} from "../../utils";
import {defaultClass, statusClasses} from "./data";

export default function LeadsTable () {
  const {token} = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [error, setError] = useState("");

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useErrorNotification(error);

  const columns = [
    {
      title: "",
      dataIndex: "case_number",
      render: (text, record) => <>
        <Link to={`/leads?id=${record.id}`}>#{text}</Link>
      </>
    },
    {
      title: "CreatedOn",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (text, record) => (
        <>
          {
            record.created_at ?
              getDateAndTime(record.created_at).date: "N/A"
          }
        </>
      )

    },
    {
      title: "Status",
      dataIndex: "case_status",
      render: (text, record) =>
        <div>
          <span className={statusClasses[text] || defaultClass}>
            {text}
          </span>
        </div>
    },
    {
      title: "ClientName",
      dataIndex: "patient_name",
      render: (text, record) => text || "N/A"
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      render: (text, record) => (text || "N/A")
    },
    {
      title: "Service",
      dataIndex: "service_name",
      render: (text, record) => text || "N/A"
    }
  ]

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      try {
        const resp = await fetchList("care-request-cases?paginate=0", token, false);
        setData(resp.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    loadData();

  }, []);

  return (
    error ? <Banner type={"error"} text={error} dismissible={false}/> :
      <div className="row">
        <div className="col-sm-12">
          <div className="card card-table show-entire">
            <div className="card-body">
              {/* Table Header */}
              <div className="page-table-header mb-2">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="doctor-table-blk">
                      <h3>{"Service Requests"}</h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Table Header */}
              {
                loading ? <SpinAnimation/> :
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: data.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        //showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={data}

                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                    />
                  </div>
              }

            </div>
          </div>
        </div>
      </div>
  )
}
