import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

// /**
//  * Get time in AM/PM from an ISO-formatted date string
//  * @param dt - an iso-formatted date string
//  * @returns {string}
//  */
// export function getTime24h(dt) {
//   return dayjs(dt).format("h:mm A");
// }


/**
 * Formats an ISO-formatted date string into local date and time
 * @param dateStr - an ISO datetime string
 * @param format_24h - whether to use 24-hour format (the default) or AM/PM format
 * @param longDate - whether to use long date format. example: Wed, Mar 19, 2025
 * @returns {{date: string, time: string, datetime: string}}
 */
export function getDateAndTime(dateStr, format_24h=false, longDate=false) {
  const dateTime = dayjs(dateStr);
  let d;
  if (longDate) {
    d = dateTime.format("ddd, MMM D, YYYY");
  } else {
    d = dateTime.format("YYYY-MM-DD");
  }

  let t;
  if (format_24h) {
    t = dateTime.format("HH:mm:ss");
  } else {
    t = dateTime.format("h:mm A");
  }
  return {date: d, time: t, datetime: `${d}, ${t}`};
}

/**
 * Get the current date and time as an ISO string
 * @returns {string}
 */
export function getCurrentISODateTime() {
  return dayjs.utc().toISOString();
}

/**
 * Truncate text and add a suffix to replace the trimmed substring
 * @param {string} str - the string to be truncated
 * @param {number} length - the max length of the string. Defaults to 100 characters
 * @param ending - the suffix to put in place of the trimmed substring. Defaults to an ellipsis
 * @returns {string}
 */
export function truncate_text(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}