import useCustomNavigation from "./useCustomNavigation";
import {useEffect, useState} from "react";

/**
 * A hook that returns the parameters for searching for users
 * @returns {{dateTo: string, dateFrom: string, status: string}}
 */
export default function useUserSearchParams() {
  const {queryParams} = useCustomNavigation();
  const [email, setEmail] = useState("");
  const [phone_number, set_phone_number] = useState("");
  const [name, setName] = useState("");
  const [id_number, set_id_number] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    setEmail(queryParams.get("email"));
    setName(queryParams.get("name"));
    set_phone_number(queryParams.get("phone_number"));
    set_id_number(queryParams.get("id_number"));
    setRole(queryParams.get("role"));
  }, [queryParams]);

  return({email, phone_number, name, id_number, role});
}