/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {Table} from "antd";
import SpinAnimation from "../Ui_Elements/SpinAnimation";
import {pdficon, pdficon3, pdficon4, plusicon, searchnormal} from '../imagepath';
import {itemRender, onShowSizeChange} from '../Pagination'
import {Link} from 'react-router-dom';
import {useAuth} from "../../hooks/AuthProvider";
import useErrorNotification from "../../hooks/useErrorNotification";
import PropTypes from "prop-types";
import Banner from "../Ui_Elements/Banner";
import ControlledComponent from "../AccessControl/ControlledComponent";
import dayjs from "dayjs";
import {fetchList} from "../../api/requestHandlers";

const AppointmentsTable = ({patientID, caregiverID, dateStart, dateEnd, title, status}) => {
  const {token, userRoles, user} = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useErrorNotification(error);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  function getLocalTime(dt) {
    return dayjs(dt).format("h:mm A");
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text, record) => (
        <Link
          to={`/appointment-details?id=${record.id}&patientID=${record.patient_id || ""}&caregiverID=${record.health_care_personnel_id || ""}`}>{text}</Link>
      )
    },
    {
      title: "Status",
      dataIndex: "appointment_status",
      sorter: (a, b) => a.appointment_status.localeCompare(b.appointment_status)
    },
    {
      title: "PatientName",
      dataIndex: "patient_name",
      sorter: (a, b) => a.patient_name.localeCompare(b.patient_name)
    },
    {
      title: "ConsultingDoctor",
      dataIndex: "health_care_personnel_name",
      sorter: (a, b) => a.health_care_personnel_name.localeCompare(b.health_care_personnel_name)
    },
    {
      title: "Service",
      dataIndex: "service_name",
      render: (text, record) => (record.service_name || record.appointment_description),
    }, {
      title: "Date",
      dataIndex: "appointment_start_date",
      sorter: (a, b) => a.appointment_start_date.localeCompare(b.appointment_start_date),
      render: (text, record) => (
        <>
          {dayjs(record.appointment_start_date).format("YYYY-MM-DD")}
        </>
      )

    }, {
      title: "Time",
      render: (text, record) => (
        <>
          {/*{text.split(" ")[1]}*/}
          {
            `${getLocalTime(record.appointment_start_date)} - ${getLocalTime(record.appointment_start_date)}`
          }
        </>
      )
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (text, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v"/>
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <ControlledComponent allowedRoles={["admin", "caregiver"]}>
                  <Link className="dropdown-item"
                        to={encodeURI(`/appointment-details?id=${record.id}&patientID=${record.patient_id}&caregiverID=${record.health_care_personnel_id}`)}>
                    <i className="far fa-edit me-2"/>
                    Edit
                  </Link>
                </ControlledComponent>
                <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#delete_patient">
                  <i className="fa fa-trash-alt m-r-5"></i> Delete</Link>
              </div>
            </div>
          </div>
        </>
      ),
    },

  ]

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      try {
        let q = "";
        const dateFilter = `appointment_start_date_from=${dateStart || "0001-01-01"}&appointment_start_date_to=${dateEnd || "9999-12-12"}&`;
        q += dateFilter;
        if (status) {
          q += `appointment_status=${status}&`
        }
        if (userRoles.includes("admin")) {
          // Get appointments for a specific staff/patient, or get all appointments
          if (patientID) {
            q += `patient_id=${patientID}&`
          }
          if (caregiverID) {
            q += `health_care_personnel_id=${caregiverID}&`
          }
        } else if (userRoles.includes("patient")) {
          q += `patient_id=${user.id}&`
        } else if (userRoles.includes("caregiver")) {
          q += `health_care_personnel_id=${user.id}&`
        }
        const apiData = await fetchList(`appointment-schedule?paginate=0&${q}`, token, false); // Fetch the data
        setData(apiData); // Update the state with the fetched data
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    loadData();

  }, [dateStart, dateEnd, patientID, caregiverID, title, status]);

  return (
    error ? <Banner type={"error"} text={error} dismissible={false}/> :
      <div className="row">
        <div className="col-sm-12">
          <div className="card card-table show-entire">
            <div className="card-body">
              {/* Table Header */}
              <div className="page-table-header mb-2">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="doctor-table-blk">
                      <h3>{title || "Appointment List"}</h3>
                      <div className="doctor-search-blk">
                        <div className="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search here"
                            />
                            <Link className="btn">
                              <img
                                src={searchnormal}
                                alt="#"
                              />
                            </Link>
                          </form>
                        </div>
                        <ControlledComponent allowedRoles={["admin", "patient"]}>
                          <div className="add-group">
                            <Link to="/addappointments"
                                  className="btn btn-primary add-pluss ms-2"
                            >
                              <img src={plusicon} alt="#"/>
                            </Link>
                          </div>
                        </ControlledComponent>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto text-end float-end ms-auto download-grp">
                    <Link to="#" className=" me-2">
                      <img src={pdficon} alt="#"/>
                    </Link>
                    <Link to="#" className=" me-2">
                    </Link>
                    <Link to="#" className=" me-2">
                      <img src={pdficon3} alt="#"/>
                    </Link>
                    <Link to="#">
                      <img src={pdficon4} alt="#"/>
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Table Header */}
              {
                loading ? <SpinAnimation/> :
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: data.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        //showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={data.data}

                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                    />
                  </div>
              }

            </div>
          </div>
        </div>
      </div>
  )
}

AppointmentsTable.propTypes = {
  caregiverID: PropTypes.string,
  patientID: PropTypes.string,
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
};

export default AppointmentsTable;

