/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from "react";
import { Container, Typography, Button, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, FormHelperText, TextField } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import {useNotifications} from "../../hooks/NotificationProvider";
import {fetchData, postData} from "../../api/requestHandlers";
import {useAuth} from "../../hooks/AuthProvider";
import SpinAnimation from "../Ui_Elements/SpinAnimation";
import useCustomNavigation from "../../hooks/useCustomNavigation";

const RatingForm = ({id, ratingData, appointmentID, caregiverID }) => {
  const notifications = useNotifications();
  const {goBack} = useCustomNavigation();
  const {token, user} = useAuth();
  const isDisabled = Boolean(id || ratingData);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    rating: "",
    communication_rating: "",
    time_keeping_rating: "",
    would_recommend: null,
    review_comment: ""
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: name === "would_recommend" ? value === "true" : value
    });
  };

  const validate = () => {
    let newErrors = {};
    if (!formData.rating) {
      newErrors.serviceQuality = "This field is required";
    }
    if (formData.review_comment.length > 200) {
      newErrors.review = "Review must be 200 characters or less";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      const data = {
        ...formData,
        health_care_personnel_id: caregiverID,
        appointment_schedule_id: appointmentID,
        made_by: user.id,
      };

      try {
        setLoading(true);
        await postData("caregiver-rating", token, data);
        notifications.addNotification("Thank you for your feedback!", "success");
        goBack();
      } catch (e) {
        notifications.addNotification(e.message);
      } finally {
        setLoading(false);
      }


      setErrors({});
    }
  };

  // Load rating details if provided
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        let data;
        if (ratingData) {
          data = ratingData;
        } else {
          data = await fetchData(`caregiver-rating/${id}`, token);
        }
        setFormData({
          ...formData,
          rating: data.rating || "",
          communication_rating: data.communication_rating || "",
          time_keeping_rating: data.time_keeping_rating || "",
          would_recommend: data.would_recommend || "",
          review_comment: data.review_comment || ""
        });
      } catch (e) {
        notifications.addNotification(`Error loading rating data: ${e.message}`, "error");
      } finally {
        setLoading(false);
      }
    }
    if (id || ratingData) {
      loadData();
    }
  }, [ratingData]);

  return (
    <>
      {
        loading ? <SpinAnimation /> :
          <Container maxWidth="sm" sx={{ mt: 4, p: 3, borderRadius: 2, boxShadow: 3, backgroundColor: "#fff" }}>
            <Typography variant="h5" gutterBottom align="center">
              Service Feedback Form
            </Typography>
            <form onSubmit={handleSubmit}>
              {[
                {
                  name: "rating",
                  label: "How would you rate the overall quality of the service? (1 = Very Poor, 5 = Excellent)",
                  required: true,
                },
                {
                  name: "communication_rating",
                  label: "How would you rate the staff's communication? (1 = Very Poor, 5 = Excellent)",
                  required: false,
                },
                {
                  name: "time_keeping_rating",
                  label: "How would you rate the staff's time management? (1 = Very Poor, 5 = Excellent)",
                  required: false,
                },
              ].map((item) => (
                <FormControl key={item.name} component="fieldset" margin="normal" fullWidth error={!!errors[item.name]} disabled={isDisabled}>
                  <FormLabel>{item.label}</FormLabel>
                  <RadioGroup name={item.name} value={formData[item.name]} onChange={handleChange} row>
                    {[1, 2, 3, 4, 5].map((value) => (
                      <FormControlLabel key={value} value={String(value)} control={<Radio />} label={String(value)} />
                    ))}
                  </RadioGroup>
                  {errors[item.name] && <FormHelperText>{errors[item.name]}</FormHelperText>}
                </FormControl>
              ))}

              <FormControl component="fieldset" margin="normal" fullWidth disabled={isDisabled}>
                <FormLabel>Based on the services received, are you likely to recommend us to others?</FormLabel>
                <RadioGroup
                  name="would_recommend"
                  value={formData.would_recommend === true ? "true" : formData.would_recommend === false ? "false" : ""}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <TextField
                label="Additional Comments (Optional)"
                name="review_comment"
                value={formData.review_comment}
                onChange={handleChange}
                multiline
                disabled={isDisabled}
                rows={3}
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 200 }}
                error={!!errors.review}
                helperText={`${errors.review || "Max 200 characters"} (${200 - formData.review_comment.length} remaining)`}
              />

              {!isDisabled && (
                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                  Submit Feedback
                </Button>
              )}
            </form>
          </Container>
      }
    </>
  );
};

RatingForm.propTypes = {
  id: PropTypes.string,
  ratingData: PropTypes.object,
  appointmentID: PropTypes.string.isRequired,
  caregiverID: PropTypes.string.isRequired
}

export default RatingForm;