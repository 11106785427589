/* eslint-disable no-unused-vars */
import React, {useEffect} from 'react'
import {Table} from "antd";
import {onShowSizeChange, itemRender} from '../Pagination'
import Header from '../Header';
import Sidebar from '../Sidebar';
import {
  imagesend, pdficon, pdficon3, pdficon4, plusicon, refreshicon, searchnormal
} from '../imagepath';
import {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useAuth} from "../../hooks/AuthProvider";
import useErrorNotification from "../../hooks/useErrorNotification";
import {useNotifications} from "../../hooks/NotificationProvider";
import SpinAnimation from "../Ui_Elements/SpinAnimation";
import ContentWrapper from "../Dashboard/ContentWrapper";
import ControlledComponent from "../AccessControl/ControlledComponent";
import AvatarImage from "../Ui_Elements/AvatarImage";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {deleteData, fetchList} from "../../api/requestHandlers";

const DoctorList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {token, userRoles} = useAuth();
  const navigate = useNavigate();
  const notifications = useNotifications();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  useErrorNotification(error);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleDelete = (itemID) => {
    setLoading(true);

    deleteData(`user/${itemID}`, token)
      .then(response => {
        notifications.addNotification('Caregiver successfully deleted!', 'success');
      })
      .catch(error => {
        notifications.addNotification(`Error: ${error.message}`, 'error');
      })
      .finally(() => {
        setLoading(false);
        navigate(0);
      });
    };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <>
          <h2 className="profile-image">
            <Link to="#" className="avatar avatar-sm me-2">
              <AvatarImage imgURL={record.user_images?.thumbnail || ""}/>
            </Link>
            <Link to={`/profile?id=${record.id}` }>{text}</Link>
          </h2>

        </>
      )
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Location",
      dataIndex: "location",
      render: (text, record) => userRoles.includes("patient") ? "Kasarani, D.O. Office, Mwiki Rd." : text || "N/A"
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (text, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v"/>
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item" to={`/add-caregiver?id=${record.id}`}>
                  <i className="far fa-edit me-2"/>
                  Edit
                </Link>
                <Link
                  className="dropdown-item"
                  onClick={() => handleDelete(record.id)}
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete</Link>
              </div>
            </div>
          </div>
        </>
      ),
    },

  ]

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const apiData = await fetchList("user?role=caregiver&paginate=0", token, false); // Fetch the data
        setData(apiData); // Update the state with the fetched data
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  return (
    <>
      <Header/>
      <Sidebar
        id={userRoles.includes("admin") ? "menu-item1" : ""}
        id1={userRoles.includes("admin") ? "menu-items1" : ""}
        activeClassName="doctor-list"
      />
      <div className="page-wrapper">
        <ContentWrapper>
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Healthcare Providers </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right"/>
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Healthcare Providers List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Healthcare Providers</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                />
                                <Link className="btn">
                                  <img
                                    src={searchnormal}
                                    alt="#"
                                  />
                                </Link>
                              </form>
                            </div>
                            <div className="add-group">
                              <ControlledComponent allowedRoles={["admin"]}>
                                <Link
                                  to="/add-caregiver"
                                  className="btn btn-primary add-pluss ms-2"
                                >

                                  <img src={plusicon} alt="#"/>
                                </Link>
                              </ControlledComponent>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#"/>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#"/>
                        </Link>
                        <Link to="#" className=" me-2">
                        </Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#"/>
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#"/>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  {loading ? <SpinAnimation/> :
                    <div className="table-responsive doctor-list">
                      <Table
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          // showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={data.data}

                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                      />
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </ContentWrapper>
        <div className="notification-box">
          <div className="msg-sidebar notifications msg-noti">
            <div className="topnav-dropdown-header">
              <span>Messages</span>
            </div>
            <div className="drop-scroll msg-list-scroll" id="msg_list">
              <ul className="list-box">
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">R</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Richard Miles </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"/>
                        <span className="message-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                    </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item new-message">
                      <div className="list-left">
                        <span className="avatar">J</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">John Doe</span>
                        <span className="message-time">1 Aug</span>
                        <div className="clearfix"/>
                        <span className="message-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                    </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">T</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Tarah Shropshire </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"/>
                        <span className="message-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                    </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">M</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Mike Litorus</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"/>
                        <span className="message-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                    </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">C</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Catherine Manseau </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"/>
                        <span className="message-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                    </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">D</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Domenic Houston </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"/>
                        <span className="message-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                    </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">B</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Buster Wigton </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"/>
                        <span className="message-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                    </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">R</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Rolland Webber </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"/>
                        <span className="message-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                    </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">C</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Claire Mapes </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"/>
                        <span className="message-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                    </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">M</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Melita Faucher</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"/>
                        <span className="message-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                    </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">J</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Jeffery Lalor</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"/>
                        <span className="message-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                    </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">L</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Loren Gatlin</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"/>
                        <span className="message-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                    </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">T</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Tarah Shropshire</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"/>
                        <span className="message-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                    </span>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <Link to="#">See all messages</Link>
            </div>
          </div>
        </div>
      </div>
      <div id="delete_doctor" className="modal fade delete-modal" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src={imagesend} alt="#" width={50} height={46}/>
              <h3>Are you sure want to delete this ?</h3>
              <div className="m-t-20">
                {" "}
                <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                  Close
                </Link>
                <button type="submit" className="btn btn-danger">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default DoctorList;
