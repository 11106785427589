import axios from 'axios';
import { BASE_URL } from '../settings';

/**
 * Centralized API request handler
 * @param {string} method - HTTP method (GET, POST, PUT, DELETE)
 * @param {string} url - API endpoint (relative path)
 * @param {string} token - Bearer authentication token
 * @param {object} [data] - Optional request payload
 * @param {boolean} isFormData - a boolean flag indicating whether the data is JSON (default) or FormData
 * @returns {Promise<object>} - API response data
 */
async function request(method, url, token="", data = null, isFormData = false) {
  try {

    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    };
    // If sending FormData, do not set 'Content-Type'; the browser will handle it
    if (!isFormData) {
      headers['Content-Type'] = 'application/json';
    }

    const response = await axios({
      method,
      url: `${BASE_URL}/${url}`,
      headers,
      data,
    });

    return response.data;

  } catch (error) {
    handleApiError(error);
  }
}

/**
 * Handles API errors globally
 * @param {object} error - Axios error object
 */
function handleApiError(error) {
  if (error.response) {
    const { status, data } = error.response;
    let message = data?.message || error.response.statusText;

    if (status === 401) {
      console.error(`${status}: ${message}`);
      // Remove credentials from localstorage, if any, to force reauthentication
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      message = ('You are logged out, please log in to continue.');
      window.location.reload();
    } else if (status === 403) {
      console.error(`${status}: ${message}`);
      message = 'You are not authorized to access the requested action/resource.';
    } else if (status >= 500) {
      console.error(`${status}: ${message}`);
      message = 'An unexpected error occurred, please try again or contact tech support.';
    }

    throw new Error(`${status}: ${message}`);
  } else if (error.request) {
    console.error("No response from server");
    throw new Error('No response from server, please try again later or contact tech support.');
  } else {
    console.error(`An uncaught error occurred: ${error.message}`);
    throw new Error("An unexpected error occurred, please try again or contact tech support.");
  }
}

// API Wrapper Functions
export async function fetchData (url, token="")  {
  const resp = await request('GET', url, token);
  if (resp.data) {
    return resp.data;
  } else {
    return {};
  }
}

export const postData = (url, token="", data, isFormData=false) => request('POST', url, token, data, isFormData);
export const putData = (url, token="", data, isFormData=false) => request('PUT', url, token, data, isFormData);
export const deleteData = (url, token="") => request('DELETE', url, token);

// Fetch a list, optionally paginated
export async function fetchList(url, token="", paginated = true) {
  const data = await fetchData(url, token);
  return paginated
    ? { data: data.data, next_url: data.links?.next_page_url, prev_url: data.links?.previous_page_url }
    : { data };
}

// Fetch role by name. If name is not specified, return all roles
export async function getRole(roleName = null, token) {
  const roles = await fetchData('role', token);
  return roleName ? roles.find((r) => r.name.toLowerCase() === roleName.toLowerCase()) || null : roles;
}

// // Return a map of all roles
// export async function getRolesMap(token) {
//   const roles = await fetchData('role', token);
//   return roles.reduce((map, role) => {
//     map[role.name] = role;
//     return map;
//   }, {});
// }

/**
 * Fetches an image and returns it object URL
 * @param {string} url - API endpoint (relative path)
 * @param {string} token - Bearer authentication token
 * @returns {Promise<string>} - API response data
 */
export async function fetchImage(url, token="") {
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}`, Accept: "image/jpeg" },
      responseType: "blob",
    });
    return URL.createObjectURL(response.data);

  } catch (error) {
    handleApiError(error);
  }
}