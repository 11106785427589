/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker} from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {Link, useLocation, useNavigate, useNavigationType} from 'react-router-dom';
import {useAuth} from "../../hooks/AuthProvider";
import SpinAnimation from "../Ui_Elements/SpinAnimation";
import useErrorNotification from "../../hooks/useErrorNotification";
import {useNotifications} from "../../hooks/NotificationProvider";
import ContentWrapper from "../Dashboard/ContentWrapper";
import {fetchData, getRole, postData, putData} from "../../api/requestHandlers";

const AddPatients = () => {
  const {token} = useAuth();
  const notifications = useNotifications();
  const location = useLocation();
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const patientID = queryParams.get("id");

  const [loading, setLoading] = useState(false);
  const [patientRoleID, setPatientRoleID] = useState(null);
  const [surname, setSurname] = useState("");
  const [nationalID, setNationalID] = useState("");
  const [otherNames, setOtherNames] = useState("");
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [bio, setBio] = useState("");

  useErrorNotification(error);

  const onDateChange = (date, dateString) => {
    setDateOfBirth(dateString);
  };

  /**
   * Navigate back to the previous page or back to the dashboard
   */
  function goBack() {
    if (navigationType === 'POP') {
      navigate('/'); // Redirect to a fallback route
    } else {
      navigate(-1); // Go back to the previous page
    }
  }

  async function onSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const jsonData = {
      phone_number: phoneNumber,
      email,
      location: address,
      bio,
      id_number: nationalID,
      dob: dateOfBirth,
      gender,
      role: patientRoleID,
      password: phoneNumber,
      surname,
      other_names: otherNames
    };
    try {
      if (patientID) {
        const resp = await putData(`user/${patientID}`, token, jsonData);
        notifications.addNotification("User details successfully updated", "success");
        goBack();
      } else {
        const resp = await postData('user', token, jsonData);
        notifications.addNotification("User successfully registered", "success");
        goBack();
      }

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  // Fetch and set the patient role
  useEffect(() => {
    const getPatientRole = async () => {
      const role = await getRole("patient", token);
      if (role) {
        setPatientRoleID(role.id);
      }
      else {
        throw new Error("An unexpected error occurred when fetching the 'patient' role. " +
          "Verify that this role exists.");
      }
    };
    getPatientRole();
  }, []);

  // Populate form with patient details if we are in edit mode
  useEffect(() => {
    const populateForm = async () => {
      if (patientID) {
        setLoading(true);
        const resp = await fetchData(`user?role=patient&id=${patientID}&paginate=0`, token);
        if (resp.length === 0) {
          setError(`Patient record not found. Verify that the id ${patientID} is correct`);
          return;
        }
        const patientData = resp[0];
        setSurname(patientData.surname || "");
        setOtherNames(patientData.other_names || "");
        setPhoneNumber(patientData.phone_number || "");
        setEmail(patientData.email || "");
        setNationalID(patientData.id_number || "");
        setAddress(patientData.location || "");
        setBio(patientData.bio || "");
        setDateOfBirth(patientData.dob || "");
        setGender(patientData.gender || "");
      }
    };
    populateForm().then(() => setLoading(false));
  }, []);

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <>
        <div className="page-wrapper">
          <ContentWrapper>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Patients </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right"/>
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      { patientID ? "Edit Patient" : "Add Patient" }
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {
              loading ? <SpinAnimation/> :
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-body">
                        <form onSubmit={onSubmit}>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-heading">
                                <h4>Patient Details</h4>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                              <div className="form-group local-forms">
                                <label>
                                  Surname <span className="login-danger">*</span>
                                </label>
                                <input
                                  required
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  value={surname}
                                  onChange={(e) => {
                                    setSurname(e.target.value)
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-12 col-md-6 col-xl-4">
                              <div className="form-group local-forms">
                                <label>
                                  Other Names <span className="login-danger">*</span>
                                </label>
                                <input
                                  required
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  value={otherNames}
                                  onChange={(e) => {
                                    setOtherNames(e.target.value)
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Phone Number <span className="login-danger">*</span>
                                </label>
                                <input
                                  required
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  value={phoneNumber}
                                  onChange={(e) => {
                                    setPhoneNumber(e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Email <span className="login-danger">*</span>
                                </label>
                                <input
                                  required
                                  className="form-control"
                                  type="email"
                                  placeholder=""
                                  autoComplete="username"
                                  value={email}
                                  onChange={(e) => {
                                    setEmail(e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  National ID number <span className="login-danger">*</span>
                                </label>
                                <input
                                  required
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  value={nationalID}
                                  onChange={(e) => {
                                    setNationalID(e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms cal-icon">
                                <label>
                                  Date Of Birth (Optional)
                                </label>
                                {/* <input
                              className="form-control datetimepicker"
                              type="text"
                              placeholder=""
                            /> */}
                                <DatePicker
                                  className="form-control datetimepicker"
                                  onChange={onDateChange}
                                  suffixIcon={null}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group select-gender">
                                <label className="gen-label">
                                  Gender<span className="login-danger">*</span>
                                </label>
                                <div className="form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      required
                                      type="radio"
                                      value="Male"
                                      checked={gender === "Male"}
                                      name="gender"
                                      className="form-check-input"
                                      onChange={(e) => {
                                        setGender(e.target.value)
                                      }}
                                    />
                                    Male
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      required
                                      type="radio"
                                      name="gender"
                                      value="Female"
                                      checked={gender === "Female"}
                                      className="form-check-input"
                                      onChange={(e) => {
                                        setGender(e.target.value)
                                      }}
                                    />
                                    Female
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Address <span className="login-danger">*</span>
                                </label>
                                <input
                                  required
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  value={address}
                                  onChange={(e) => {
                                    setAddress(e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-12">
                              <div className="form-group local-forms">
                                <label>
                                  Bio
                                </label>
                                <textarea
                                  className="form-control"
                                  rows={3}
                                  cols={30}
                                  value={bio}
                                  onChange={(e) => {
                                    setBio(e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="doctor-submit text-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary submit-form me-2"
                                >
                                  Submit
                                </button>
                                <button
                                  type={"reset"}
                                  onClick={() => goBack()}
                                  className="btn btn-primary cancel-form"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
            }

          </ContentWrapper>
          <div className="notification-box">
            <div className="msg-sidebar notifications msg-noti">
              <div className="topnav-dropdown-header">
                <span>Messages</span>
              </div>
              <div className="drop-scroll msg-list-scroll" id="msg_list">
                <ul className="list-box">
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                        <span className="message-author">Richard Miles </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix"/>
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item new-message">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">John Doe</span>
                          <span className="message-time">1 Aug</span>
                          <div className="clearfix"/>
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Tarah Shropshire{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix"/>
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Mike Litorus</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix"/>
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Catherine Manseau{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix"/>
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">D</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Domenic Houston{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix"/>
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">B</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Buster Wigton{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix"/>
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Rolland Webber{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix"/>
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author"> Claire Mapes </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Melita Faucher</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Jeffery Lalor</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">L</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Loren Gatlin</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            Tarah Shropshire
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to="#">See all messages</Link>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddPatients;
