/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Card, Button, Form, FormControl } from "react-bootstrap";
import { ChevronDown, ChevronUp, Trash, Edit } from "react-feather";
import useErrorNotification from "../../hooks/useErrorNotification";
import {fetchList} from "../../api/requestHandlers";
import {useAuth} from "../../hooks/AuthProvider";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {Link} from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import ContentWrapper from "../Dashboard/ContentWrapper";

export default function ServiceManagement() {
  const { token } = useAuth();
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [servicesLoading, setServicesLoading] = useState(false);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [editingCategory, setEditingCategory] = useState(null);
  const [editingServices, setEditingServices] = useState({});

  useErrorNotification(error);

  // Load the service categories
  useEffect(() => {
    async function fetchData() {
      try {
        setCategoriesLoading(true);
        const categoryData = await fetchList("service-category?paginate=0", token, false);
        categoryData.data.sort((a, b) => a.name.localeCompare(b.name));
        setCategories(categoryData.data);
      } catch (e) {
        setError(e.message);
      } finally {
        setCategoriesLoading(false);
      }
    }
    fetchData();
  }, []);

  // Load the services under the expanded category
  useEffect(() => {
    async function fetchData() {
      try {
        setServicesLoading(true);
        const services = await fetchList(`service?service_category_id=${expandedCategory}&paginate=0`, token, false);
        services.data.sort((a, b) => a.name.localeCompare(b.name));
        setServices(services.data);
      } catch (e) {
        setError(e.message);
      } finally {
        setServicesLoading(false);
      }
    }
    if (expandedCategory) {
      fetchData();
    }
  }, [expandedCategory]);

  function handleEditCategory(id, newName) {
    console.log("Updated category:", id, newName);
  }

  function handleEditService(id, field, value) {
    console.log("Updated service:", id, field, value);
  }

  function handleDeleteCategory(id) {
    console.log("Deleted category:", id);
  }

  function handleDeleteService(id) {
    console.log("Deleted service:", id);
  }

  return (
    <>
      <Header/>
      <Sidebar/>
      <>
        <div className="page-wrapper">
          <ContentWrapper>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right"/>
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Manage Services</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}

            <div className="container mt-4">
              <h3 className="mb-4">Service Categories</h3>
              {categories.map((category) => (
                <Card key={category.id} className="mb-3 p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    {editingCategory === category.id ? (
                      <FormControl
                        value={category.name}
                        onChange={(e) =>
                          setCategories((prev) =>
                            prev.map((c) =>
                              c.id === category.id ? { ...c, name: e.target.value } : c
                            )
                          )
                        }
                        onBlur={() => {
                          handleEditCategory(category.id, category.name);
                          setEditingCategory(null);
                        }}
                        autoFocus
                      />
                    ) : (
                      <span>{category.name}</span>
                    )}
                    <div className="d-flex gap-2">
                      <Button variant="light" onClick={() => setEditingCategory(category.id)}>
                        <Edit size={16} />
                      </Button>
                      <Button
                        variant="light"
                        onClick={() =>
                          setExpandedCategory(
                            expandedCategory === category.id ? null : category.id
                          )
                        }
                      >
                        {expandedCategory === category.id ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                      </Button>
                      <Button variant="danger" onClick={() => handleDeleteCategory(category.id)}>
                        <Trash size={16} />
                      </Button>
                    </div>
                  </div>
                  {expandedCategory === category.id && (
                    <div className="mt-3">
                      {services
                        .map((service) => (
                          <Card key={service.id} className="p-2 mb-2">
                            <div className="row">
                              {Object.entries(service).map(([key, value]) => (
                                key !== "id" && key !== "service_category_id" ? (
                                  <div key={key} className="col-md-6 d-flex align-items-center mb-2">
                                    <strong className="me-2">{key.replace('_', ' ')}:</strong>
                                    {typeof value === "boolean" ? (
                                      <Form.Check
                                        type="switch"
                                        checked={value}
                                        onChange={(e) => handleEditService(service.id, key, e.target.checked)}
                                      />
                                    ) : (
                                      <FormControl
                                        value={value}
                                        onChange={(e) =>
                                          setEditingServices((prev) => ({
                                            ...prev,
                                            [service.id]: { ...prev[service.id], [key]: e.target.value },
                                          }))
                                        }
                                        onBlur={() => handleEditService(service.id, key, editingServices[service.id]?.[key] || value)}
                                      />
                                    )}
                                  </div>
                                ) : null
                              ))}
                            </div>
                            <Button variant="danger" onClick={() => handleDeleteService(service.id)}>
                              <Trash size={16} />
                            </Button>
                          </Card>
                        ))}
                    </div>
                  )}
                </Card>
              ))}
            </div>
          </ContentWrapper>
        </div>
      </>

    </>
  );
}
