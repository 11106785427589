/* eslint-disable no-unused-vars */
import React, {useEffect, useMemo, useState} from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import {imguser} from "../../imagepath";
import {DatePicker} from "antd";
import Select from "react-select";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {Link} from "react-router-dom";
import ControlledComponent from "../../AccessControl/ControlledComponent";
import useCustomNavigation from "../../../hooks/useCustomNavigation";
import {useAuth} from "../../../hooks/AuthProvider";
import SpinAnimation from "../../Ui_Elements/SpinAnimation";
import ContentWrapper from "../../Dashboard/ContentWrapper";
import Banner from "../../Ui_Elements/Banner";
import useErrorNotification from "../../../hooks/useErrorNotification";
import {useNotifications} from "../../../hooks/NotificationProvider";
import {fetchData, postData, fetchImage, putData} from "../../../api/requestHandlers";

const EditProfile = () => {

  const {queryParams} = useCustomNavigation();
  const userID = queryParams.get("id");
  const notifications = useNotifications();
  const {user, token, reloadUser} = useAuth();
  const [error, setError] = useState("");
  const [fatalError, setFatalError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const {goBack} = useCustomNavigation();
  useErrorNotification(error);

  const [surname, setSurname] = useState("");
  const [otherNames, setOtherNames] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState({});
  const [nationalID, setNationalID] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");

  // User images
  const [profileImgUpload, setProfileImgUpload] = useState("");
  const [imgPreviewURL, setImgPreviewURL] = useState("");

  // Location information
  const [county, setCounty] = useState("");
  const [subCounty, setSubCounty] = useState("");
  const [ward, setWard] = useState("");

  // Fields specific to caregivers
  // Add: exact designation/department

  // Role information
  const roleOptions = [
    {value: "admin", label: "Admin"},
    {value: "caregiver", label: "Healthcare Personnel"},
    {value: "management", label: "Management"},
    {value: "patient", label: "Patient"}
  ];
  const [userRoles, setUserRoles] = useState([]);

  const genderOptions = [
    {value: "Male", label: "Male"},
    {value: "Female", label: "Female"},
  ];

  const onDateOfBirthChange = (date, dateString) => {
    setDob(dateString);
  };

  async function onRoleChange(event) {
    //
  }

  async function handleSubmit(event) {
    try {
      setLoading(true);
      event.preventDefault();
      if (event.target.checkValidity()) {
        const jsonData = {
          surname,
          other_names: otherNames,
          dob,
          gender: gender.value,
          id_number: nationalID,
          phone_number: mobile,
          email,
          bio,
        };
        const resp = await putData(`user/${userID}`, token, jsonData);
        if (resp.data.id === user.id) {
          reloadUser(resp.data);
        }
        notifications.addNotification("User details successfully updated", "success");
        goBack();
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  function loadFile (event) {
    const maxSize = 2 * 1024 * 1024;
    const file = event.target.files[0];
    if (file && file.size > maxSize) {
      notifications.addNotification("File size exceeds 2MB. Please choose a smaller file.", "error");
      event.target.value = ""; // Clear the input
      return;
    }
    if (file) {
      setProfileImgUpload(file.name);
      setProfileImgUpload(file);
    }
  }

  useEffect(() => {
    if (!userID) {
      setFatalError("User ID has not specified in the URL");
    }
  }, [])

  // Load user details
  useEffect(() => {
    async function loadUserData() {
      try {
        setLoading(true);
        const data = await fetchData(`user/${userID}`, token);
        setSurname(data.surname || "");
        setOtherNames(data.other_names || "");
        setDob(data.dob || "");
        setGender(
          data.gender === "Male" ? {value: "Male", label: "Male"} : {value: "Female", label: "Female"}
        );
        setNationalID(data.id_number || "");
        setMobile(data.phone_number || "");
        setEmail(data.email || "");
        setBio(data.bio || "");
        // Set roles
        const roles = [];
        data.roles.map((role) => {
          if (role.name === "admin") {
            roles.push({label: "Admin", value: role.name});
          } else if (role.name === "caregiver") {
            roles.push({label: "Healthcare Personnel", value: role.name});
          } else if (role.name === "management") {
            roles.push({label: "Management", value: role.name});
          } else if (role.name === "patient") {
            roles.push({label: "Patient", value: role.name});
          }
        });
        setUserRoles(roles);

        // User image
        const img = data.user_images || null;
        setImgPreviewURL(img?.thumbnail || "");
      } catch (e) {
        setFatalError(e.message);
      } finally {
        setLoading(false);
      }
    }

    if (userID) {
      loadUserData().then(r => console.log("User details loaded"));
    }
  }, [])

  // Upload a new profile picture
  useEffect(() => {
    async function uploadProfilePicture() {
      try {
        setImageLoading(true);
        const formData = new FormData();
        formData.append("image", profileImgUpload);
        formData.append("user_id", userID);
        await postData(`user-image`, token, formData, true);
        console.log('Profile image successfully updated');
        //   Fetch the link to the new URL
        const userData = await fetchData(`user/${userID}`, token);
        if (userData.id === user.id) {
          reloadUser(userData);
        }
        setImgPreviewURL(userData.user_images?.thumbnail || "");
        notifications.addNotification("The profile image has been changed successfully", "success");
      } catch (e) {
        console.error(e);
        setError(`Failed to load profile picture: ${e.message}`);
      } finally {
        setImageLoading(false);
      }
    }

    if (profileImgUpload) {
      uploadProfilePicture();
    }
  }, [profileImgUpload]);

  return (
    <>
      <Header/>
      <Sidebar/>
      <>
        <div className="page-wrapper">
          <ContentWrapper>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right"/>
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Profile</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {
              fatalError ? <Banner type={"error"} text={fatalError} dismissible={false}/> :
                <>
                  {
                    loading ? <SpinAnimation/> :
                      <div className="row">
                        <div className="col-sm-12">
                          <form onSubmit={handleSubmit}>
                            <ControlledComponent allowedRoles={["admin"]}>
                              <div className="card-box">
                                <h3 className="card-title">Roles of this user</h3>
                                <div className="row">
                                  <div className="col-md-6 form-group local-forms">
                                    <Select
                                      value={userRoles}
                                      onChange={setUserRoles}
                                      isMulti
                                      options={roleOptions}
                                    />
                                  </div>
                                </div>
                              </div>
                            </ControlledComponent>
                            <div className="card-box">
                              <h3 className="card-title">Basic Information</h3>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="profile-img-wrap">
                                    {
                                      imageLoading ? <SpinAnimation /> :
                                        <img className="inline-block" src={ imgPreviewURL || imguser } alt="user"/>
                                    }
                                    <div className="fileupload btn">
                                      <span className="btn-text">edit</span>
                                      <input
                                        type="file"
                                        accept="image/jpeg, image/jpg, image/png"
                                        name="file"
                                        required
                                        id="file"
                                        onChange={loadFile}
                                        className="upload"
                                      />
                                    </div>
                                  </div>
                                  <div className="profile-basic">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group local-forms">
                                          <label className="focus-label">Surname</label>
                                          <input
                                            type="text"
                                            value={surname}
                                            onChange={(e) => setSurname(e.target.value)}
                                            className="form-control floating"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group local-forms">
                                          <label className="focus-label">Other Names</label>
                                          <input
                                            type="text"
                                            value={otherNames}
                                            onChange={(e) => setOtherNames(e.target.value)}
                                            className="form-control floating"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group local-forms ">
                                          <label className="focus-label">Date of Birth</label>
                                          <div className="cal-icon">
                                            <DatePicker
                                              className="form-control datetimepicker"
                                              onChange={onDateOfBirthChange}
                                              suffixIcon={null}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group local-forms">
                                          <label className="focus-label">Gender</label>
                                          <Select
                                            onChange={setGender}
                                            value={gender}
                                            options={genderOptions}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group local-forms">
                                          <label className="focus-label">National ID Number</label>
                                          <input
                                            type="number"
                                            value={nationalID}
                                            onChange={(e) => setNationalID(e.target.value)}
                                            className="form-control floating"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-box">
                              <h3 className="card-title">Contact Information</h3>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group local-forms">
                                    <label className="focus-label">Phone Number</label>
                                    <input
                                      type="text"
                                      value={mobile}
                                      onChange={(e) => setMobile(e.target.value)}
                                      className="form-control floating"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group local-forms">
                                    <label className="focus-label">E-mail</label>
                                    <input
                                      type="email"
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      className="form-control floating"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-box">
                              <h3 className="card-title">Location Information</h3>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group local-forms">
                                    <label className="focus-label">County</label>
                                    <input
                                      type="text"
                                      className="form-control floating"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group local-forms">
                                    <label className="focus-label">Sub-county</label>
                                    <input
                                      type="text"
                                      className="form-control floating"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group local-forms">
                                    <label className="focus-label">Ward</label>
                                    <input
                                      type="text"
                                      className="form-control floating"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-box">
                              <h3 className="card-title">Bio</h3>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group local-forms">
                      <textarea
                        className="form-control floating"
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                      />
                                  </div>
                                </div>

                              </div>
                            </div>
                            <div className="card-box text-center m-t-20">
                              <button className="btn btn-primary submit-btn" type="submit">
                                Save
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                  }
                </>
            }
          </ContentWrapper>
        </div>
      </>
    </>
  );
};

export default EditProfile;
