/* eslint-disable no-unused-vars */
import { favicon } from "../imagepath";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useAuth} from "../../hooks/AuthProvider";
import Banner from "../Ui_Elements/Banner";
import SpinAnimation from "../Ui_Elements/SpinAnimation";
import useErrorNotification from "../../hooks/useErrorNotification";
import {DatePicker} from "antd";
import {useNotifications} from "../../hooks/NotificationProvider";
import useCustomNavigation from "../../hooks/useCustomNavigation";
import dayjs from "dayjs";
import {fetchData, postData, putData} from "../../api/requestHandlers";

export default function AddLicenseOrCertificationForm({type, id, caregiverID}) {
  const {token} = useAuth();
  const notifications = useNotifications();
  const {goBack} = useCustomNavigation();

  const typeCapitalized = type? `${type.charAt(0).toUpperCase() + type.slice(1)}` : "";
  const {userRoles, user} = useAuth();
  const [error, setError] = useState("");
  const [fatalError, setFatalError] = useState("");
  const [loading, setLoading] = useState(false);
  const [verified, setverified] = useState(false);

  // Warning messages used when the entire form of certain elements have been disabled
  const verifiedWarning = "Editing has been disabled because this document has already been verified."
  const unverifiedButUploaded = "Note: You may edit the file details but you cannot remove or change the uploaded PDF";

  // Form props
  const [documentTitle, setDocumentTitle] = useState("");
  const [documentID, setDocumentID] = useState("");
  const [issuer, setIssuer] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [fileName, setFileName] = useState('');
  const [fileObj, setFileObj] = useState(null);

  const [fileURL, setFileURL] = useState("");

  const [show, setShow] = useState(true);

  useErrorNotification(error);

  function onIssueDateChange(date, dateString) {
    setIssueDate(dateString);
  }

  function onExpiryDateChange(date, dateString) {
    setExpiryDate(dateString);
  }

  function loadFile (event) {
    const maxSize = 2 * 1024 * 1024;
    const file = event.target.files[0];
    if (file && file.size > maxSize) {
      notifications.addNotification("File size exceeds 2MB. Please choose a smaller file.", "error");
      event.target.value = ""; // Clear the input
      return;
    }
    if (file) {
      setFileName(file.name);
      setFileObj(file);
    }
  }

  // Open the PDF  in a separate window
  function expandPDF() {
    const pdfUrl = document.getElementById('pdfPreview').src;
    window.open(pdfUrl, '_blank');
  }

  async function onSubmit(event) {
    setLoading(true);
    event.preventDefault();

    if (!issueDate) {
      setError("Date of issue must be provided");
      setLoading(false);
      return;
    }
    if (event.target.checkValidity) {
      const data = {
        health_care_personnel_id: caregiverID,
        expiry_date: expiryDate || null,
        issued_by: issuer || null,
      }
      if (type === "license") {
        data.license_number = documentID || null;
        data.license_name = documentTitle || null;
        data.issued_date = issueDate || null;
        if (!id) {
          // Do not allow the file to be modified
          data.license_file = fileObj || null;
        }
      } else if (type === "certification") {
        data.issue_date = issueDate || null;
        data.certification_name = documentTitle || null;
        if (!id) {
          // Do not allow the file to be modified
          data.certificate_file = fileObj || null;
        }
      }

      try {
        const suffix = (type === "license" ? "licensing" : "certification");
        if (id) {
          const resp = await putData(`caregiver-${suffix}/${id}`, token, data);
          notifications.addNotification(`${type} successfully updated`, "success");
          goBack();
        } else {
          const formData = new FormData();
          for (const key in data) {
            formData.append(key, data[key]);
          }
          const resp = await postData(`caregiver-${suffix}`, token, formData, true);
          console.log(`${type} uploaded successfully, object ID: ${resp.data.id}`);
          notifications.addNotification(`${type} successfully added`, "success");
          goBack();
        }
      } catch (err) {
        setError(err.message);
      }
    }

    setLoading(false);
  }

  // Check for errors in the parameters
  useEffect(() => {
    if (!caregiverID) {
      setFatalError("The query parameter 'caregiverID' is required");
    } else if (!type) {
      setFatalError("The query parameter 'type' is required");
    } else if (!["license", "certification"].includes(type)) {
      setFatalError(`Invalid certification type '${type || 'null'}'. Must be either 'license' or 'certification'`);
    } else if (!(userRoles.includes("admin") || user.id === caregiverID)) {
      setFatalError("You do not have enough permission to perform the requested action");
    }
  }, []);

  // Load item data from the API if id has been provided
  useEffect(() => {
    const populateForm = async () => {
      setLoading(true);
      try {
        if (type === "license") {
          const data = await fetchData(`caregiver-licensing/${id}`, token);
          setDocumentTitle(data.license_name || "");
          setDocumentID(data.license_number || "");
          setIssuer(data.issued_by || "");
          setIssueDate(data.issued_date || "");
          setExpiryDate(data.expiry_date || "");
          setFileURL(data.license_file || "");
          // Form is disabled if the document has already been verified
          setverified(!!data.license_verified_at);
        } else if (type === "certification"){
          const data = await fetchData(`caregiver-certification/${id}`, token);
          setDocumentTitle(data.certification_name || "");
          setIssuer(data.issued_by || "");
          setIssueDate(data.issue_date || "");
          setExpiryDate(data.expiry_date || "");
          setFileURL(data.certificate_file || "");
          // Form is disabled if the document has already been verified
          setverified(!!data.certificate_verified_at);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (id && !fatalError) {
      populateForm().then(() => {
        console.log(`${type} loaded successfully`);
      });
    }
  }, [id]);

  return (
    <>
      {
        fatalError ? <Banner text={fatalError} type={"error"} dismissible={false}/> :
          <form onSubmit={onSubmit}>
            {
              verified ? <Banner text={verifiedWarning} type={"warning"} dismissible={true}/> : null
            }
            {
              (!verified && fileURL) ? <Banner text={unverifiedButUploaded} type={"warning"} dismissible={true}/> : null
            }
            {
              loading ? <SpinAnimation/> :
                <div className="row">
                  <div className="col-12">
                    <div className="form-heading">
                      <h4>{typeCapitalized} Details</h4>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group local-forms">
                      <label>
                        Title of {type}<span className="login-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        disabled={verified}
                        value={documentTitle}
                        required
                        onChange={(e) => setDocumentTitle(e.target.value)}
                      />
                    </div>
                  </div>

                  {
                    type === "license" ?
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            {typeCapitalized} Number<span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            disabled={verified}
                            value={documentID}
                            required
                            onChange={(e) => setDocumentID(e.target.value)}
                          />
                        </div>
                      </div>: null
                  }

                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group local-forms">
                      <label>
                        Name of Issuer<span className="login-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        placeholder={"Example: KMPDC"}
                        disabled={verified}
                        type="text"
                        value={issuer}
                        required
                        onChange={(e) => setIssuer(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group local-forms cal-icon">
                      <label>
                        Date of Issue{" "}
                        <span className="login-danger">*</span>
                      </label>
                      <DatePicker
                        className="form-control datetimepicker"
                        value={issueDate ? dayjs(issueDate, "YYYY-MM-DD") : ""}
                        onChange={onIssueDateChange}
                        suffixIcon={null}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group local-forms cal-icon">
                      <label>
                        Expiry Date
                      </label>
                      <DatePicker
                        className="form-control datetimepicker"
                        value={expiryDate ? dayjs(expiryDate, "YYYY-MM-DD") : ""}
                        onChange={onExpiryDateChange}
                        suffixIcon={null}
                      />
                    </div>
                  </div>
                  {/* Only display file upload input if user is creating a new upload */}
                  {
                    id ? null :
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-top-form ">
                        <label className="local-top">
                          Upload PDF of {type} <span className="login-danger">*</span>
                        </label>
                        <div className="settings-btn upload-files-avator">
                          <input
                            type="file"
                            accept="application/pdf"
                            name="file"
                            disabled={verified}
                            required
                            id="file"
                            onChange={loadFile}
                            className="hide-input"
                          />
                          <label htmlFor="file" className="upload">
                            Choose File
                          </label>
                          {fileName && (
                            <div>
                              <span className="local-top">Selected file: {fileName}</span>
                            </div>
                          )}
                        </div>
                        <div
                          className="upload-images upload-size"
                          style={{display: show ? "none" : "block"}}
                        >
                          <img src={favicon} alt="Image"/>
                          <Link to="#" className="btn-icon logo-hide-btn">
                            <i
                              className="feather-x-circle"
                              onClick={() => setShow((s) => !s)}
                            >
                              <FeatherIcon icon="x-circle"/>
                            </i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  }
                  {
                    fileURL &&
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <div
                          style={{width: "100%", height: "500px", overflow: "hidden", border: "1px solid #ddd"}}
                        >
                          <iframe
                            id="pdfPreview"
                            src={fileURL}
                            width="100%"
                            height="500px"
                            style={{border: "none"}}>
                          </iframe>
                        </div>
                        <button
                          onClick={expandPDF}
                          className="btn btn-secondary"
                        >
                          Open in Full Screen
                        </button>
                      </div>
                    </div>
                  }

                  <div className="col-12">
                    <div className="doctor-submit text-end">
                      <button
                        type="submit"
                        disabled={verified}
                        className="btn btn-primary submit-form me-2"
                      >
                        Submit
                      </button>
                      <button
                        type={"reset"}
                        onClick={() => goBack()}
                        className="btn btn-primary cancel-form"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
            }
          </form>
      }
    </>
  );
}

AddLicenseOrCertificationForm.propTypes = {
  type: PropTypes.string.isRequired,
  caregiverID: PropTypes.string,
  id: PropTypes.string,
}