import {avatar} from "../imagepath"

import PropTypes from "prop-types";
import {useState} from "react";

export default function AvatarImage({ imgURL="" }) {
  const [src, setSrc] = useState(imgURL);
  return (
    <img
      className="avatar rounded-circle"
      src={src}
      width={48}
      height={48}
      style={{objectFit: "cover"}}
      loading="lazy"
      alt="avatar"
      onError={() => setSrc(avatar)}
    />
  );
}

AvatarImage.propTypes = {
  imgURL: PropTypes.string,
}