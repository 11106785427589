/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */

// Displays a filer bar for use in searching for users based on various fields
// on the User model. The keys and values of the selected filters are added to the
// URL when the Search button is clicked. Components using the filter bar will be
// expected to listen for changes in the URL and make queries using the search parameters.

import React, {useState} from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import useCustomNavigation from "../../hooks/useCustomNavigation";

export default function UserFilterBar({includeRoles = false}) {

  const { setQueryParams } = useCustomNavigation();
  const [selectedSearchKey, setSelectedSearchKey] = useState(null);
  const [selectedRole, setSelectedRole] = useState({value: "caregiver", label: "Healthcare Personnel"});

  const [searchValue, setSearchValue] = useState("");

  const searchOptions = [
    {value: "email", label: "Email"},
    {value: "id_number", label: "National ID Number"},
    {value: "name", label: "Name"},
    {value: "phone_number", label: "Phone Number"},
  ];

  const roleOptions = [
    {value: "admin", label: "Admin"},
    {value: "caregiver", label: "Healthcare Personnel"},
    {value: "management", label: "Management"},
  ];

  function onSearchButtonClick(event) {
    event.preventDefault();
    // Reset the query params
    setQueryParams({
      name: "",
      email: "",
      id_number: "",
      role: "",
      phone_number: ""
    });
    const field = selectedSearchKey?.value;
    const params = {};
    if (selectedRole) {
      params.role = selectedRole.value;
    }
    if (selectedSearchKey && searchValue) {
      params[field] = searchValue;
    }
    if (Object.keys(params).length > 0) {
      setQueryParams(params);
    }
  }

  function resetForm(event) {
    // const formModified = selectedRole || selectedSearchKey || searchValue;
    event.preventDefault();
    setSelectedSearchKey(null);
    setSearchValue("");
    setSelectedRole(null);
    setQueryParams({
      name: "",
      email: "",
      id_number: "",
      role: "",
      phone_number: ""
    });
  }

  return (
    <div className="card">
      <div className="staff-search-table">
        <form>
          <div className="row">
            {/* Selection of search key */}
            <div className="col-12 col-md-6 col-xl-3">
              <div className="form-group local-forms">
                <label>
                  Search by
                </label>
                <Select
                  menuPortalTarget={document.body}
                  styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                  value={selectedSearchKey}
                  onChange={setSelectedSearchKey}
                  options={searchOptions}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                      boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                      '&:hover': {
                        borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                      },
                      borderRadius: '10px',
                      fontSize: "14px",
                      minHeight: "45px",
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                      transition: '250ms',
                      width: '35px',
                      height: '35px',
                    }),
                  }}
                />
              </div>
            </div>

            {/* Input field for providing search value */}
            <div className="col-12 col-md-6 col-xl-3" hidden={selectedSearchKey === null}>
              <div className="form-group local-forms">
                <label>{selectedSearchKey ? `${selectedSearchKey.label}` : 'Select a search option'}</label>
                <input
                  type="text"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>

            {/* Selection of role */}
            {
              includeRoles && <div className="col-12 col-md-6 col-xl-3">
                <div className="form-group local-forms">
                  <label>
                    Role of User
                  </label>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                    value={selectedRole}
                    onChange={setSelectedRole}
                    options={roleOptions}
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                        boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                        '&:hover': {
                          borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                        },
                        borderRadius: '10px',
                        fontSize: "14px",
                        minHeight: "45px",
                      }),
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                        transition: '250ms',
                        width: '35px',
                        height: '35px',
                      }),
                    }}
                  />
                </div>
              </div>
            }

            {/* Reset button */}
            <div className="col-12 col-md-6 col-xl-3">
              <div className="form-group local-forms doctor-submit">
                <button
                  className="btn btn-secondary"
                  onClick={resetForm}
                >
                  Reset Filters
                </button>
              </div>
            </div>

            {/* Search form submission button */}
            <div className="col-12 col-md-6 col-xl-3">
              <div className="form-group local-forms doctor-submit">
                <button
                  className="btn btn-primary submit-list-form me-2"
                  onClick={onSearchButtonClick}
                >
                  Search
                </button>
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>
  );
}

UserFilterBar.propTypes = {
  includeRoles: PropTypes.bool,
};